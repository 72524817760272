.itemDetailsGrandContainer {
    padding: 56px;
    background-color: #fff;
    border-radius: 12px;
    overflow: hidden;
}

.itemDetailsGrandContainer h3 {
    margin: 0;
    font-size: 22px;
    font-weight: 400;
}