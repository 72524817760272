.ComboBox .MuiInputBase-root.MuiOutlinedInput-root {
  padding: 5px 9px !important;
}

.ComboBox .MuiInputBase-input {
  padding: 7.5px 4px 7.5px 5px !important;
}

.ComboBox .MuiIconButton-root {
  padding: 2px;
}
