.modalOneMaster {
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
}
.modalOneMaster .OrdersModalOneContainer {
  background: #fff;
  border-radius: 28px;
  position: absolute;
  outline: none;
  /* padding: 20px 0 !important; */
}
.modalOneMaster .OrdersModalOneContainer .OrdersModalHeader {
  border-bottom: none;
  padding: 20px;
  /* height: 50px; */
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 28px 28px 0 0;
  font-family: "Poppins", sans-serif;
}

.modalOneMaster .OrdersModalOneContainer .OrderModalTitle {
  margin: 0 !important;
  font-size: 24px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  /* text-align: center; */
  color: #fff !important;
}

.modalOneMaster .OrdersModalOneContainer .OrderModalTitle p {
  margin: 0;
  font-size: 24px;
}

.modalOneMaster .OrdersModalOneContainer .OrderModalTitle span {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  opacity: 80%;
}

.modalOneMaster .OrdersModalOneContainer .OrderId {
  /* height: 22px; */
  padding: 5px 10px;
  border-radius: 1000px;
  background-color: #ffffff;
  font-weight: 500;
  /* color: #dd2d37; */
}

.modalOneMaster .OrdersModalOneContainer .OrderId p {
  font-size: 14px;
  margin: 0;
}

/* modal footer */
.modalOneMaster .OrderModalOneFooter {
  padding: 15px 0;
  width: 100%;
  border-top: 1px solid #eae9ec;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 28px 28px;
}

.FooterButton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.modalOneMaster .OrderModalOneFooter .FooterButton .MuiButtonBase-root {
  margin-right: 15px;
}

.modalOneMaster .OrderModalOneFooter .FooterButton .MuiButtonBase-root {
  margin-right: 15px;
  height: 38px;
  box-shadow: none;
  font-size: 14px;
}

.modalOneMaster .OrderModalOneFooter .FooterButton .btLight {
  color: #676c7b;
  background: #fff;
  border: #e4e8ec 1px solid;
  border-radius: 10px !important;
  text-transform: initial !important;
  padding: 10px 15px;
}

.modalOneMaster .OrderModalOneFooter .FooterButton .btLight:hover {
  color: #676c7b;
  background: #fff;
  border: #e4e8ec 1px solid;
}
.modalOneMaster .OrderModalOneFooter .FooterButton .btnBlue {
  color: #fff;
  background: #004fe0;
  border: #004fe0 1px solid;
  border-radius: 10px !important;
  text-transform: initial !important;
  padding: 10px 15px;
}

.modalOneMaster .OrderModalOneFooter .FooterButton .btnBlue:hover {
  color: #fff;
  background: #004fe0;
  border: #004fe0 1px solid;
}

.modalOneMaster .OrderModalOneBody {
  padding: 16px 42px;
  overflow-y: auto;
  max-height: 700px;
}

/* body section */
.OrderedUserDetails {
  min-height: 95px;
  padding: 12px;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  border: 1px solid #e4e2e6;
  border-radius: 12px;
}

.OrderUserAvatar {
  height: 40px;
  min-width: 40px;
  /* background-color: #dd2d37; */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.OrderUserAvatar .MuiAvatar-root {
  height: 100%;
  width: 100%;
}

.OrderUserAvatar .MuiAvatar-root img {
  height: 24px;
  width: 24px;
  color: #fff;
}

.OrderUserInfo h3 {
  margin: 0;
  font-size: 16px;
}
.OrderUserInfo P {
  margin: 0;
}

.OrderUserInfo P span {
  margin: 0;
  color: #767680;
  display: inline;
}
/* .OrderUserInfo P span:nth-child(2n){
    margin: 0 0 15px 0;
    padding-bottom: 35px;
    font-size: 35px;
    color: red;
} */

.orderlistParent {
  margin-top: 20px;
  padding-bottom: 12px;
  border-bottom: 1px solid #eae9ec;
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  justify-content: space-between;
}

.orderlistParent P {
  margin: 2px 0 0 0;
  font-size: 22px;
}

.orderlistItemDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.orderlistItemDetails h2 {
  margin: 0;
  font-weight: 600;
}

.orderlistItemInfo {
  margin: 10px 0 0 15px;
  padding-left: 1rem;
  border-left: 2px solid #adadaf;
}

.orderlistItemInfo span {
  margin: 0;
  color: #77767a;
  font-size: 14px;
}

.orderlistItemInfo p {
  margin: 0;
  /* color: #77767A; */
  font-size: 14px !important;
  font-weight: 600;
}

.listFooterParent {
  margin: 10px 0;
  padding: 10px 0 0 0;
  /* border-top: 1px solid #ecebee; */
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.listFooterContainer {
  width: 50%;
}

.listFooterItem {
  margin: 8px 0;
  display: flex;
  justify-content: space-between;
}

.listFooterContainer P {
  margin: 0;
  color: #1b1b1f;
}

.ModaClose {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

/* print button  */
.Printbutton {
  margin-left: 10px;
  padding: 15px 15px;
  border: 2px solid #767680;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.DeliveryContainer {
  padding: 18px 0;
  display: flex;
  gap: 8px;
  align-items: flex-start;
  border-bottom: 1px solid #eae9ec;
}

.DeliveryContainer p {
  margin: 0;
  color: #0450e1;
  font-size: 14px;
}

.DeliveryBoxHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media (max-width: 1400px) {
  .modalOneMaster .OrderModalOneBody {
    max-height: 300px;
  }
}

@media (max-width: 1100px) {
  .modalOneMaster .OrderModalOneBody {
    max-height: 290px;
  }
}
