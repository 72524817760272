.alertMessage {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    left: 0;
    z-index: 999999;
    top: 20px;
}

.alertMessage .MuiAlert-standardSuccess{
    margin: 0 auto;
}