.MenuIconOpen{
    position: relative;
    top: 100px;
}

.MenuIconClose {
    position: absolute;
    top: 73px;
    right: -49px;
    z-index: 99999;
    background: #000;
    border-radius: 0 8px 8px 0;
}

.MenuIconClose svg{
    color: #fff;
}




