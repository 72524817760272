@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.TableTwoContainer {
  background: #fff;
  /* border-radius: 12px; */
  box-shadow: 0px 0px 6px #00000017;
  overflow: hidden;
}

.TableContainer.BorderTable {
  box-shadow: none !important;
  border: #e4e8ec 1px solid;
}

.TableContainer.CondensedTable .MuiTableCell-root {
  padding: 12px 20px;
}

.Table span {
  margin: 0;
}

.TableContainer .MuiPaper-elevation1 {
  box-shadow: none !important;
}

.TableInnerHeader {
  background: #fff;
  padding: 12px 20px;
  border-radius: 12px 12px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: #e4e8ec 1px solid;
}

.TableInnerHeader h3 {
  color: #0b132b;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  font-family: "Poppins", sans-serif;
}

.TableContainer .MuiTablePagination-root {
  border-radius: 0 0 12px 12px;
}

.TableCell {
  font-family: "Poppins", sans-serif;
}

.TableCell span {
  font-family: "Poppins", sans-serif;
  display: inline-flex;
}
.CellCont {
  display: flex;
  align-items: center;
}

.TableCell .CellCont .CellImg {
  display: flex;
  align-items: center;
}

.TableCell .CellCont .MuiAvatar-root {
  margin-right: 16px;
  padding: 0px;
}

.TableCell .CellCont .textString {
  font-size: 14px;
  color: #1b1b1f;
}

.TableCell .CellCont .textBold {
  font-weight: 500;
}

.TableCell .subtitle {
  color: #676c7b;
  font-size: 14px !important;
  display: block;
  text-decoration: underline;
}

.TableCell .icon img {
  width: 40px;
  margin-right: 16px;
}

.status {
  display: flex;
  width: fit-content;
  padding: 2px 5px;
  font-weight: 600;
  font-size: 12px;
}
.rounded {
  border-radius: 4px;
}
.success {
  color: #0b9444;
  background: #e1f2e8;
}

.warning {
  color: #ffaa00;
  background: #ffe7b6;
}

.error {
  color: #ff0033;
  background: #ffe0e6;
}

.inprogress {
  color: #004fe0;
  background: #e0eafb;
}

.inprogress-color {
  color: #004fe0;
}

.bold {
  font-weight: 600;
}

.Table .MuiTableCell-root {
  font-size: 14px;
  color: #1b1b1f;
  padding: 6px 17px !important;
}

.Table .TableHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
}

.Table {
  border-radius: 10px;
  width: 100%;
}

.Table .TableTitle {
  color: #0b132b;
  font-size: 34px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin: 0px;
}

.Table .button {
  display: flex;
  padding: 6px 16px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 8px;
  text-decoration: none;
  justify-content: center;
  width: 102px;
}

.Table .ActionButton {
  display: flex;
  padding: 6px 16px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 8px;
  text-decoration: none;
  justify-content: center;
  color: #004fe0;
  background: #e0eafb;
}

.Table .MuiTableCell-root h1 {
  color: #0b132b;
  font-size: 14px;
  margin: 0;
}

.Table .MuiTableCell-root p {
  margin: 0;
}

.Table .MuiTableCell-root a {
  color: #676c7b;
  font-size: 14px;
  width: max-content !important;
}

.Table .TableImgCell .MuiAvatar-root {
  margin-right: 18px;
}

.Table .TableImgCell {
  display: flex;
}

.Dark {
  color: #676c7b;
}

.Table .MuiTableHead-root .MuiTableCell-head {
  background: #fcfcfc;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #676c7b;
}

.MuiTableCell-root {
  font-family: "Poppins", sans-serif !important;
}

.MuiTableBody-root {
  overflow-x: auto !important;
}

.Tablechip {
  border-radius: 100px !important;
  padding: 3px 8px;
  margin: 0 8px;
  background: #dbece3;
  color: #0b9444;
  font-size: 10px;
  font-weight: 600;
}

.tablelink {
  max-width: 305px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tablelink a {
  color: #004fe0 !important;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
}

.textDate {
  font-size: 14px;
  color: #676c7b;
}

.tableIcon {
  margin-right: 15px;
}

.tableIcon a {
  background: #e4e8ec;
  width: 32px;
  height: 32px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableIcon a svg {
  font-size: 24px;
}

/* .ActionButtons .MuiButtonBase-root{
    background: rgb(255, 255, 255);
    color: rgb(0, 79, 224);
    height: 40px;
    font-size: 14px;
    border: 1px solid rgb(228, 232, 236);
    width: auto;
    border-radius: 8px !important;
    padding: 0 20px !important;
    text-transform: initial !important;
    font-weight: 600 !important;
    display: flex;
    align-items: center;
    justify-content: center;
} */

.MuiMenu-paper {
  border: #e4e8ec 1px solid;
  margin-top: 2px;
}

.MuiMenu-paper .MuiListItem-root {
  padding: 5px 30px !important;
  cursor: pointer;
}

.MuiMenu-paper .MuiListItem-root .MuiListItemText-root .MuiTypography-root {
  font-size: 14px;
  color: #676c7b;
}

.TableInnerHeaderLeft {
  display: flex;
  align-items: center;
}

.TableInnerHeaderRight {
  display: flex;
  align-items: center;
}

.TableInnerHeaderRight .FieldLabel {
  display: none;
}

.TableInnerHeaderRight .ButtonContainer {
  margin-left: 15px;
}

.TableInnerHeaderRight .MuiSelect-select {
  min-width: 134px;
}

.TableInnerHeaderRight .MuiOutlinedInput-notchedOutline {
  border-radius: 8px !important;
  height: 48px;
}

.TableInnerHeaderRight .TextField {
  position: relative;
  top: 3px;
}

.TableSearchField .MuiInputBase-input {
  padding: 11px;
}

.TableSearchField .MuiOutlinedInput-notchedOutline {
  height: 48px;
}

.TableSearchField .MuiIconButton-root {
  background: #676c7b;
  color: #fff;
  border-radius: 0px 8px 8px 0;
  padding: inherit;
  height: 41px;
  width: 41px;
  right: 1px;
  top: 0px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.TableSearchField .MuiIconButton-root:hover {
  background: #676c7b;
  color: #fff;
  border-radius: 0px 8px 8px 0;
  padding: inherit;
  height: 41px;
  width: 41px;
  right: 1px;
  top: 0px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.TableSearchField .MuiInputBase-root {
  width: 360px;
}

.TableCell .textLightLink {
  display: block;
  text-decoration: underline;
  font-size: 14px;
  color: #676c7b;
}

.FontMedium {
  font-weight: 500 !important;
}

.TableInfo {
  font-weight: 600 !important;
  font-size: 14px !important;
}

.TableLabel {
  color: #676c7b !important;
  font-size: 14px !important;
  font-weight: 400;
}

.oddEvenTable .MuiTableCell-root {
  border: none !important;
}

.TableRedio .MuiRadio-colorSecondary.Mui-checked {
  color: #004fe0;
}

.FilterTitle {
  font-size: 14px;
  color: #0b132b;
  font-weight: 600;
  border-bottom: #e4e8ec 1px solid;
  margin-bottom: 20px;
  padding-bottom: 16px;
}

.FilterContainer {
  position: relative;
}

.FilterContainer .MuiPaper-root {
  margin-left: -164px;
}

.filterBody {
  width: 264px;
  padding: 15px;
}

.FilterButtonGroup {
  display: flex;
  justify-content: end;
  margin-top: 15px;
}

.FilterButtonGroup .ButtonContainer {
  margin-left: 10px;
}

.FilterCheckBox p {
  margin-bottom: 0;
}

.FilterCheckBox .MuiFormGroup-root {
  display: flex !important;
  flex-direction: initial !important;
  flex-wrap: wrap;
}

.FilterButton {
  border: #e4e8ec 1px solid !important;
  height: 48px !important;
  color: #004fe0 !important;
  border-radius: 8px !important;
  text-transform: initial !important;
  padding: 0 20px !important;
}

.FilterContainer .MuiPaper-root.MuiPaper-elevation {
  border-radius: 12px !important;
}

.statussmall {
  font-family: "Poppins", sans-serif;
  display: inline-flex;
  min-width: 22px;
  text-align: center;
  justify-content: center;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #004fe0 !important;
}

@media (max-width: 768px) {
  .TableInnerHeader {
    display: block;
  }

  .PeronalInfocard {
    display: block;
    text-align: center;
  }

  .PeronalInfocardDetail {
    display: block;
  }

  .PeronalInfocard .UserIcon {
    margin-right: 0;
    text-align: center;
    display: inline-block;
  }

  .TableSearchField .MuiInputBase-root {
    width: 100%;
  }
}

.MuiButtonBase-root .MuiTableSortLabel-icon {
  display: none;
}
