.hoursContainer{
    padding: 56px;
    background: #FFFFFF;
    border-radius: 12px;
    box-shadow: 0 0 5px #F2F0F4;
}

.hoursContainer h4 {
    margin: 0;
    font-size: 22px;
    font-weight: 400;
}