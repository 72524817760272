.FieldPasswordC ::placeholder {
  color: #ccc;
  font-size: 16px;
}

.FieldPassword .custom-input {
  height: 48px;
  border-radius: 8px;
  border: #e4e8ec 1px solid;
  padding: 0 15px;
  width: 94%;
  color: #676c7b;
  font-size: 16px;
}

.FieldPassword {
  position: relative;
}

.FieldPassword .icon-span {
  position: absolute;
  right: 10px;
  top: 13px;
}

.FieldPassword .icon-span svg {
  color: #303034;
}

.tracker-box {
  padding-top: 15px;
}

.not-validated {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.validated {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  text-decoration: line-through;
}

.not-validated svg {
  color: #303034;
  font-size: 16px;
}

.validated svg {
  color: #4c9f41;
  font-size: 16px;
}

.list-icon {
  margin-right: 10px;
  display: flex;
}

.tracker-box {
  color: #676c7b;
  font-size: 14px;
}

.TextFiledPassword .MuiSvgIcon-root {
  color: #303034 !important;
}
