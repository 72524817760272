.ModalOne .ModalOneContainer {
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  margin-top: 88px;
}

.ModalOne .ModalOneIconCtn .closeIcon {
  color: #000000;
  position: absolute;
  right: 16px;
  cursor: pointer;
  top: 21px;
}

.ModalOne .ModalOneMdlBody h2 {
  font-size: 20px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 600;
  margin: 0;
  margin-bottom: 24px;
}

.ModalOne .ModalOneMdlBody p {
  font-size: 14px;
  color: #4c5e67;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
  margin: 0;
  margin-bottom: 8px;
}

.ModalOne .ModalOneMdlBody a {
  color: #0b132b;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
}

.ModalTitle h1 {
  font-size: 22px;
  font-weight: 600;
  color: #0b132b;
  text-align: center;
  margin: 0;
}

/* ==== Modal two === */

.ModalTwo .ModalTwoContainer {
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 28px;
  padding-bottom: 0 !important;
}

.ModalTwo .ModalTwoIconCtn .closeIcon {
  color: #000000;
  position: absolute;
  right: 16px;
  cursor: pointer;
  top: 21px;
}

.ModalTwo .ModalTitle {
  font-size: 22px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400 !important;
  margin: 0;
  margin-bottom: 20px;
  text-align: center;
}

.ModalTwo .ModalTwoMdlBody p {
  font-size: 14px;
  color: #4c5e67;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
  margin: 0;
  margin-bottom: 8px;
  text-align: center;
}

.ModalTwo .ModalTwoMdlBody a {
  color: #0b132b;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
}

.ModalTwo .ModalTitle h1 {
  font-size: 22px;
  font-weight: 600;
  color: #0b9444;
  text-align: center;
  margin: 0;
}

.ModalTwoMdlFooter {
  background: #f7f9fa;
  border-top: #e4e8ec 1px solid;
  margin-left: -24px;
  margin-right: -24px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  border-radius: 0 0 4px 4px;
}

.ModalTwoMdlFooter .ButtonContainer {
  margin: 0 !important;
}

.ModalTwoMdlFooter .ButtonContainer .MuiButtonBase-root {
  margin: 0 !important;
}

@media (max-width: 768px) {
  .ModalThree .ModalThreeContainer {
    width: 100% !important;
  }

  .SignContainerInner {
    padding: 20px;
    padding-top: 0;
  }

  .ModalTwo .ModalTwoContainer {
    width: 80% !important;
  }

  .ModalTwoMdlFooter {
    border-radius: 0 0 28px 28px;
  }

  .ModalOneContainer {
    top: 30% !important;
    left: 12% !important;
    transform: initial !important;
    width: 70% !important;
    margin: 0 5%;
    padding: 10% !important;
    margin-top: 0 !important;
  }
}
