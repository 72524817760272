.menuFileUploaderGrandParent {
    width: 97%;
    margin: 10px 0;
    border: 1px solid #E4E8EC !important;
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 5px;
    display: inline-block;
    cursor: pointer !important;
}

  .menuFileUploaderGrandParent::before {
    content: ""; 
  }
  
  .menuFileUploaderGrandParent::after {
    content: ""; 
  }
  