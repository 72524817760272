.MuiFormControl-root {
  width: 100%;
}

.FieldLabel span {
  margin: 0;
}

.MuiOutlinedInput-notchedOutline {
  border-radius: 8px !important;
}

.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #d32f2f !important;
}

.InnerFieldCount {
  position: absolute;
  right: 15px;
  top: 24px;
  transform: translateY(-65%);
  color: #676c7b;
  font-size: 14px;
}
.TextField .MuiFormControl-root:has(.InnerFieldCount) .MuiInputBase-root {
  width: 80%;
}
