.priceAndVariationContainer {
    padding: 56px;
    background-color: #fff;
    border-radius: 12px;
    overflow: hidden;
}

.priceAndVariationContainer h2 {
    margin: 0;
    font-weight: 400;
}

.priceAndVariationContainer p { 
    margin: 0;
    font-size: 14px;
    color: #77767A;
}

.userAccssSwitch .MuiSwitch-root .MuiButtonBase-root.Mui-checked {
    color: #4C9F41 !important;
}

.userAccssSwitch .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
    background-color: #4C9F41 !important;
}