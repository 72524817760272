.FileUplaoder {
  display: flex;
  align-items: center;
  background: #f8f8f9;
  border: #e4e8ec 1px solid;
  border-radius: 4px;
  margin-top: 12px;
  padding: 5px;
  position: relative;
  justify-content: space-between;
}

.FieldLabelUpload {
  font-size: 16px !important;
  color: #0b132b;
  margin-bottom: 4px;
  font-weight: 600;
  display: flex;
  height: 26px;
  font-family: "Poppins", sans-serif !important;
}

.FileUplaoderInfo {
  display: flex;
  align-items: center;
}

.FileUplaoder span {
  color: #0b132b;
  font-size: 14px;
}

.FileUplaoder .CloseIcon {
  cursor: pointer;
  font-size: 18px;
  margin-right: 8px;
}

.FileUplaoder img {
  width: 50px;
  margin-right: 10px;
}

.UplaodField {
  margin-bottom: 10px;
}

.UplaodField input {
  border: none;
  padding: 0;
  border-radius: 8px;
  width: 100%;
  height: 48px;
}

.UplaodField button {
  border: #004fe0 1px solid;
  padding: 11px 10px;
  border-radius: 8px;
  color: #ffffff;
  background: transparent !important;
  font-weight: 600;
  cursor: pointer;
  width: 100%;
  box-shadow: none !important;
  margin-top: 20px;
  background: #004fe0 !important;
}

.custom-file-input {
  color: transparent;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: "Choose File";
  border: #e4e8ec 1px solid;
  padding: 15px 10px;
  border-radius: 8px;
  color: #004fe0;
  background: transparent !important;
  font-weight: 600;
  cursor: pointer;
  width: 100%;
  box-shadow: none !important;
  position: absolute;
  text-align: center;
  left: 0;
  font-family: "Poppins", sans-serif;
}

.UploadBody {
  padding: 0.5rem 0 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.custom-file-input:hover::before {
  border-color: black;
}
.custom-file-input:active {
  outline: 0;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

@media (max-width: 768px) {
  .custom-file-input::before {
    width: 100%;
  }
}
