.itemDetailsGrandContainer {
  padding: 56px;
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
}

.itemDetailsGrandContainer h3 {
  margin: 0;
  font-size: 22px;
  font-weight: 400;
}

.MenuItemImageContainer {
  padding: 0px 12px;
  border: 1px solid #e4e8ec;
  border-radius: 8px;
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
}

.MenuItemImageContainer p {
  flex: 1;
}
