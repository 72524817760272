.ComingSoon {
    background: #fff;
    box-shadow: 0px 0px 6px #00000017;
    border-radius: 12px;
    padding: 56px;
    text-align: center;

}

.ComingSoon img{
    width: 200px;
}

.ComingsoonCont h1 {
    color: #1B1B1F;
    font-size: 34px;
    font-family: 'Poppins', sans-serif;
    margin-top: 0;
    margin-bottom: 15px;
}

.ComingSoon h3{
    color: #1B1B1F;
    font-size: 24px;
    font-family: 'Poppins', sans-serif;
    margin-top: 30px;
    margin-bottom: 0;
}

.ComingSoon p {
    color: #5E5E62;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    max-width: 485px;
    margin: 0 auto;
}