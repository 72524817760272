.MultipleSelet .MuiInputBase-root.MuiOutlinedInput-root {
  width: 100%;
  min-height: 48px;
}

.MultipleSelet .MuiInputBase-root.MuiOutlinedInput-root em {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #c8c6ca;
}
