.DrawerContainer {
  position: relative;
  z-index: 9999;
  background: #fff;
}

.DrawerHeader {
  display: flex;
  justify-content: space-between;
  border-bottom: #e4e8ec 1px solid;
  padding: 10px 24px;
  padding-right: 0px;
  position: relative;
  z-index: 3;
  background: #fff;
  position: sticky;
  top: 0;
  align-items: center;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.09);
  margin-bottom: 20px;

}

.DrawerHeader .DrawerHeaderTitle {
  font-size: 24px;
  color: #1B1B1F;
  font-weight: 600;
}

.DrawerHeader svg {
  color: #1B1B1F;
}

.DrawerHeader .MuiButtonBase-root.MuiButton-root {
  padding: 0;
}

.DrawerBody {
  padding: 24px;
  width: 744px;
  padding-bottom: 140px;
}

.DrawerFooter {
  position: fixed;
  bottom: 1px;
  border-top: #e4e8ec 1px solid;
  background: #f8f8f9;
  width: -webkit-fill-available;
}

.InnerDrawerFooter .DrawerFooterInner {
  justify-content: flex-end;
}

.DrawerFooterInner {
  display: flex;
  justify-content: space-between;
  padding: 18px 24px;
}

.DrawerFooterButton {
  display: flex;
  align-items: center;
}

.DrawerFooter .DrawerFooterInner .DrawerCloseButton {
  color: #0b132b;
  font-size: 14px;
  font-weight: 700;
  margin-right: 8px;
}

.DrawerFooter .DrawerFooterInner .DrawerActionButton {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  background: #0450E1;
  border-radius: 8px;
  padding: 8px 20px;
  height: 48px;
}

.DrawerCloseButtonMobile {
  display: none !important;
}

.DrawerTabsContainer {
  border-bottom: #e4e8ec 1px solid;
  padding: 0 40px;
}

.DrawerTabsContainer .MuiButtonBase-root.MuiTab-root {
  padding: 0;
  margin-right: 30px;
}

.DrawerTabsContainer .MuiButtonBase-root.MuiTab-root .tabsContent {
  display: flex;
  align-items: center;
  color: #0b132b;
  text-transform: initial !important;
  font-weight: 600 !important;
  line-height: 24px;
}

.DrawerTabsContainer .MuiButtonBase-root.MuiTab-root.Mui-selected .tabsContent {
  color: #004fe0 !important;
  text-transform: initial;
}

.DrawerTabsContainer .MuiButtonBase-root.MuiTab-root .tabsContent svg {
  margin-right: 4px;
}

.DrawerTabsContainer .MuiTabs-indicator {
  background-color: #004fe0 !important;
}

.MediaLibPanelContainer {
  margin-top: 32px;
}

.DrawerActionButton svg {
  font-size: 18px;
}

.DrawerActionButtonLeftIcon {
  margin-right: 8px;
  display: flex;
}

.MuiDrawer-paper.MuiDrawer-paperAnchorRight {
  z-index: 999999;
}

/* ==== SubDrawerBody ==== */

.SubDrawerActive .MuiDrawer-paperAnchorRight {
  right: 300px !important;
}

.SubDrawerBody {
  width: 384px;
}

.SubDrawerContainer {
  background: #f8f8f9;
  height: 100%;
  position: fixed;
  border-left: #e4e8ec 1px solid;
  top: 0;
  overflow-y: auto;
  padding: 24px;
  padding-top: 90px;
  width: 384px;
}

.SubDrawer .MDC {
  display: flex;
}

.SubDrawerActive .SubDrawerBody {
  display: none;
}

.SubDrawer .DrawerFooter {
  position: fixed;
  bottom: 0;
  border-top: #e4e8ec 1px solid;
  background: #ffffff;
  width: -webkit-fill-available;
  margin-right: 399px;
}

.DrawerSummaryButton {
  color: #004fe0 !important;
  border: #e4e8ec 1px solid !important;
  font-size: 14px !important;
  font-family: 'Poppins', sans-serif;
  font-weight: 600 !important;
  padding: 10px 15px !important;
  text-transform: initial !important;
  border-radius: 8px !important;
}

.SubDrawer .ShowSummary {
  display: none;
}

.SubDrawerActive .CloseSummary {
  display: none;
}

/* ==== SubDrawerBody ==== */

.CheckBoxTabs .MuiTabs-indicator {
  display: none;
}

.CheckBoxTabs .CheckBoxTabsTabBody .MuiBox-root {
  padding: 0;
}

.CheckBoxTabs .MuiTab-root {
  border: #e4e8ec 1px solid;
  border-radius: 8px;
  margin-right: 16px;
  flex: 1;
  width: 100%;
  padding: 16px;
  max-width: initial !important;
}

.CheckBoxTabs .RedioContents {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.CheckBoxTabs .RedioContents .RedioLebel {
  margin-left: 10px;
  width: 100%;
  justify-content: space-between;
}

.CheckBoxTabs .RedioContents .RedioLebel h3 {
  margin: 0;
  font-size: 14px;
  text-transform: initial;
}

.CheckBoxTabs .MuiTab-root.Mui-selected h3 {
  color: #0b132b;
}

.CheckBoxTabs .RedioContents .RedioLebel p {
  margin: 0;
  font-size: 14px;
  text-transform: initial;
  font-size: 1rem;
  font-weight: 400;
  width: 100%;
  text-align: left;
  color: #46464A;
}

.CheckBoxTabs .MuiTab-root.Mui-selected {
  border: #004fe0 1px solid;
  border-radius: 8px;
  background: #f8f8f9;
}

.CheckBoxTabs .MuiTab-root.Mui-selected h3 {
  color: #004fe0;
}

.CheckBoxTabs .UnSelectedRedio {
  color: #ffffff;
  padding: 1px;
  border-radius: 100px;
  /* border: #A4A7B0 2px solid; */
  font-size: 12px;
}

.CheckBoxTabs .MuiTab-root.Mui-selected .UnSelectedRedio {
  display: none;
}

.CheckBoxTabs .MuiTab-root .SelectedRedio {
  display: none;
}

.CheckBoxTabs .MuiTab-root.Mui-selected .SelectedRedio {
  color: #004fe0;
  display: block;
  padding: 0;
  /* border: #004FE0 1px solid; */
  border-radius: 100px;
  font-size: 18px;
}

.CheckBoxTabs .RedioIcon {
  position: relative;
  top: -11px;
  right: -11px;
}

.CheckBoxTabs .MuiTabs-root {
  display: flex;
  overflow: visible !important;
  min-height: 48px;
  -webkit-overflow-scrolling: touch;
}

.CheckBoxTabs .MuiTabs-scroller {
  flex: 1 1 auto;
  display: inline-block;
  position: relative;
  white-space: nowrap;
  overflow: visible !important;
}

.CheckBoxTabs .MuiTab-root:last-child {
  margin-right: 0 !important;
}

.CheckBoxTabs .MuiTab-wrapper {
  flex-direction: initial !important;
  justify-content: left !important;
}

.CheckBoxTabsTabBody {
  padding-top: 32px;
}

.CheckBoxTabs {
  padding: 24px;
  padding-bottom: 0;
}

.SubDrawer .CheckBoxTabs {
  max-width: 744px;
  padding: 25px;
  padding-bottom: 0;
}

.DrawerHeaderTitleUnderHeader {
  font-size: 20px;
  color: #0b132b;
  font-weight: 600;
  padding-top: 20px;
  padding-left: 25px;
}

.MainDrawerWrapper .MuiModal-backdrop-Custom {
  background: #000;
  opacity: 0.3;
  position: fixed;
  top: 0;
  right: 744px;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.DrawerBody .MuiAccordionSummary-content {
  margin: 0 !important;
}

.DrawerBody .FilterMainCont .Mui-expanded.MuiAccordion-root {
  padding-bottom: 18px;
  margin: 0 !important;
}

.DrawerBody .TableSection {
  margin-bottom: 80px;
}

.DrawerFooterContainer {
  position: absolute;
  left: 0;
  width: 744px;
}

@media (max-width: 480px) {
  .CheckBoxTabs .MuiTab-root {
    max-width: 114px !important;
    padding-left: 7px;
  }

  .CheckBoxTabs .RedioContents {
    flex-direction: row-reverse;
  }
}

@media (max-width: 768px) {
  .DrawerBody {
    padding: 20px;
    width: 100%;
  }

  .DrawerFooterInner {
    display: block;
    text-align: left;
  }

  .DrawerCloseButton {
    display: none !important;
  }

  .DrawerCloseButtonMobile {
    display: block !important;
    margin: 0 auto !important;
  }

  .PeronalInfocard .PeronalInfocardDetail .MuiChip-root {
    margin-top: 8px;
  }

  .TabFooter {
    margin-left: -20px;
  }
}

@media (max-width: 468px) {
  .DrawerFooter .DrawerFooterInner .DrawerActionButton {
    width: 100%;
    margin-bottom: 5px;
  }

  .DrawerFooterButton {
    display: block;
    align-items: center;
  }

  .SubDrawer .DrawerBody {
    padding: 20px;
    width: 320px;
  }
}

.DrawerContainer .DrawerBody .TextArea textarea {
  width: 100%;
}

.MDC .DrawerTable {
  border: none !important;
  padding-top: 8px;
}

.MDC .TableContainer {
  padding-top: 0px !important;
}

.MDC .TableContainer .TableInnerHeader {
  padding-bottom: 16px;
  padding-top: 16px !important;
}

.DrawerContainer .TabPanel .MuiBox-root {
  padding: 0 !important;
}

.DrawerBody .InvitedUsers {
  padding-right: 22px !important;
}

.DrawerTable .MuiTable-root {
  min-width: auto !important;

}

.SmallTitle {
  font-weight: 400 !important;
  font-size: 22px !important;
}

.RedioBoxTabBody .MuiBox-root {
  padding: 0 !important;
}

.DrawerBody .TableFooter .MuiTablePagination-toolbar {
  padding: 14px 21px;
}

.DrawerContainer .TableInnerHeader {
  padding: 0px 21px;
}

.TsbPanelContainer {
  padding: 24px;
}