/* ====== Sing in css ==========*/

.SignContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    top: 0;
}


.SignContainerInner {
    width: 552px;
    margin: 0 auto;
    background: #fff;
    box-shadow: 0px 0px 32px #00000014;
    border-radius: 28px;
    position: relative;
}

.SignContainerInner h1 {
    font-size: 24px;
    color: #0B132B;
    margin: 0;
    font-weight: 400;
    text-align: center;
}

.SignForm {
    margin: 0 auto;
    padding: 40px 40px 0 40px;
    /* padding-bottom: 20px; */
}

.HideLabelClass .FieldLabel{
    display: none;
}

.SignFormFooter {
    background: #E4E8EC;
    height: 48px;
    width: 100%;
    left: 0;
    border-radius: 0 0 28px 28px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.SignFormFooter span {
    color: #676C7B;
    font-size: 14px;
    font-weight: 400;
    margin: 0 10px;
}

.SignFormFooter svg {
    color: #676C7B;
    font-size: 20px;
}


.OrBox {
    border-bottom: #d4d6db 1px solid;
    position: relative;
    height: 5px;
    text-align: center;
}

.OrBox span {
    display: inline-block;
    background: #fff;
    padding: 0px 20px;
    position: relative;
    top: -9px;
    color: #676C7B;
    font-size: 14px;
}

.SocialButton img{
    width: 30px;
    position: absolute;
    left: 10px;
}

.SignContainer .ButtonContainer .MuiButtonBase-root{
    margin: 0 !important;
}

.AlreadyText{
    display: flex;
    color: #676C7B;
    font-size: 14px;
    justify-content: center;
}

.AlreadyText a{
    color: #004FE0 !important;
    margin: 0 !important;
    margin-left: 10px !important;
}

.TosText{
    text-align: center;
    color: #676C7B;
    font-size: 14px;
}

.TosText a{
    color: #676C7B !important;
    font-size: 14px !important;
    text-decoration: underline !important;
    font-weight: 400 !important;
}

.OtpField{
    justify-content: center;
    margin-top: 24px;
}

.OtpField input{
    border:#D2D3D8 1px solid;
    width: 52px !important;
    height: 52px !important;
    border-radius: 8px;
    margin-right: 4px !important;
}

.OtpField input:nth-child(4) { 
    margin-left:15px;
    margin-right: 15px;
}

.ResendButton {
    justify-content: center !important;
    flex-wrap: wrap !important;
}

.ResendButton span {
    display: block;
    width: 100%;
    color: #4C9F41;
    font-size: 16px;
}

.ResendButton button {
    background: #fff !important;
    box-shadow: none !important;
    height: 48px !important;
    border-radius: 8px !important;
    font-weight: 600;
    text-transform: initial !important;
    font-size: 14px;
    padding: 0 20px;
    outline: none !important;
    border: none;
    color: #4C9F41 !important;
    cursor: pointer;
    margin: 0 !important;
    padding: 0 !important;
}

.OrgLogo{
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
}

.OrgLogo img{
    height: 64px;
}

.Enrolling{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fff;
}
.Enrolling p{
    font-size: 22px;
    color: #676C7B;
    margin-bottom: 0;
}

.Enrolling h1{
    font-size: 34px;
    color: #0B132B;
    padding-top: 78px;
    max-width: 744px;
    margin: 0 auto;
}

.Enrolling h2{
    font-size: 20px;
    color: #676C7B;
    font-style: italic;
    font-weight: 500;
   
}

.Enrolling .MuiCircularProgress-colorPrimary{
    color: #004FE0 !important;
    width: 64px !important;
    height: 64px !important;
}


/* ============================ SignInAccountIDLocked start ======================= */

.PageBody {
    margin-top: 0px;
}

.SuccessCard {
    max-width: 552px;
    margin: 0 auto;
    box-shadow: 0px 0px 32px #00000014;
    border-radius: 28px;

}

.SuccessCard .SuccessCardDetails {
    border: 1px solid #E4E8EC;
    padding: 40px;
    border-radius: 0px 0px 28px 28px;
    text-align: center;
}

.SuccessCard .SuccessCardDetails h2 {
    color: #0B132B;
    font-size: 20px;
    font-weight: 600 !important;
    margin: 0;
}

.SuccessCard .SuccessCardDetails p {
    color: #676C7B;
    font-size: 14px;
    font-weight: 400 !important;
    margin: 0;
}

.SuccessCard .SuccessCardDetails a {
    color: #4C9F41;
    font-size: 14px;
    font-weight: 400 !important;
    text-decoration: none !important;

}

.SuccessCard .CanceledCardHeader {
    background: #e63946;
    padding: 40px;
    border-radius: 28px 28px 0px 0px;
    text-align: center;
}

.SuccessCard .CanceledCardHeader h1 {
    color: #fff;
    font-size: 24px;
    font-weight: 500 !important;
    margin-bottom: 0;
}

.SuccessCard .CanceledCardHeader span {
    color: #fff;
    background:  #C1303B;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
}

.SuccessCard .CanceledCardHeader span.errorIcon {
    background: #C1303B !important;
    color: #fff !important;
}



/* ============================ SignInAccountIDLocked end ========================= */