@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Poppins", sans-serif !important;
  background: #f5f8fa;
}

.MuiTypography-root {
  font-family: "Poppins", sans-serif !important;
}

.MuiChip-label {
  font-family: "Poppins", sans-serif !important;
}

.MuiInputBase-input {
  font-family: "Poppins", sans-serif !important;
}
.MuiAutocomplete-option {
  font-family: "Poppins", sans-serif !important;
}

.MuiButtonBase-root {
  font-family: "Poppins", sans-serif !important;
}

.MuiMenu-paper .MuiListItem-root .MuiListItemText-root .MuiTypography-root {
  font-family: "Poppins", sans-serif !important;
}

.app-body {
  width: 80%;
  height: 400px;
  margin-left: 11%;
  padding: 20px;
  margin-top: 60px;
}

.app-second-body {
  margin-top: 0px !important;
}

.Section {
  background: #fff;
  border-radius: 10px;
  padding: 40px;
  margin-bottom: 20px;
}

.StapperContaier {
  display: flex;
}

.StapperBody {
  padding: 32px;
  width: 24%;
  height: fit-content;
  /* margin-left: 10rem; */
}

.StapperBody .MuiStepper-horizontal {
  display: block !important;
}

.StapperContent {
  margin-left: 20px;
  flex: 1;
}

.MdTitle {
  color: #181c32;
  font-size: 24px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}

.StepperButton {
  display: flex;
  justify-content: space-between;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 70%;
  margin: 0 auto;
  margin-left: 18%;
}

/* ====== Form Css Start ======*/
.FieldLabel {
  font-size: 14px;
  color: #676c7b;
  margin-bottom: 4px;
  font-weight: 400;
  display: flex;
  height: 26px;
}

.FieldLabel .MuiSvgIcon-root {
  color: #a4a7b0;
  font-size: 22px;
  margin-left: 4px;
}

.FieldLabel span {
  color: #ff0033;
}

.SecFooter {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
}

.HelperText {
  margin: 0;
  padding-top: 4px;
  font-size: 12px;
  color: #676c7b;
}

.TextField .MuiInputBase-root {
  width: 100%;
  height: 46px;
}

.TextField .MuiInputBase-root .MuiInputBase-input {
  position: relative;
  top: -3px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.FormBody
  .MuiFormControl-root
  .MuiInputBase-root
  .MuiOutlinedInput-notchedOutline {
  border-color: #e4e8ec;
}

.FormBody
  .MuiFormControl-root
  .MuiInputBase-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #004fe0;
}

/* ====== Form Css End ======*/

/* ====== Accourding Css Start ======*/
.MuiAccordionSummary-content .MuiTypography-root {
  font-weight: 600;
}

.accFooter {
  display: flex;
  justify-content: end;
  border-top: #e3e3e3 1px solid;
  padding: 10px 16px 0 0;
  margin-top: 24px;
  margin-left: -16px;
  margin-right: -16px;
}

.MuiAccordionDetails-root {
  padding: 8px 16px 10px !important;
}

/* ====== Accourding Css end ======*/

/* ====== Card tab Css end ======*/

.CardTabs .MuiTabs-indicator {
  display: none !important;
}

.CardTabs .MuiTabs-root .MuiButtonBase-root {
  border: #676c7b 1px dashed;
  padding: 20px;
  margin-right: 15px;
  background: #fff;
  border-radius: 4px;
  min-width: 312px;
  font-weight: 600;
  font-size: 14px;
}

.CardTabs .MuiTabs-root .MuiButtonBase-root.Mui-selected {
  border: #009ef7 1px dashed;
  padding: 20px;
  margin-right: 15px;
  background: #f1faff;
  color: #009ef7;
}

.CardTabs .MuiTabs-root .MuiButtonBase-root .CardTabsbutton {
  display: flex;
  align-items: center;
}

.CardTabs .MuiTabs-root .MuiButtonBase-root .CardTabsbutton svg {
  color: #d9dae1;
  margin-right: 15px;
}

.CardTabs .MuiTabs-root .MuiButtonBase-root.Mui-selected .CardTabsbutton svg {
  color: #009ef7;
}

/* ====== Card tab  Css end ======*/

/* ====== Staper Css Start ======*/
.FormStepper {
  padding-bottom: 150px;
}

.StapperContaier .MuiStepLabel-iconContainer {
  border-radius: 6px !important;
  display: inline-block;
  background: #a4a7b0;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.StapperContaier .MuiStepLabel-labelContainer {
  padding-left: 8px;
}

.StapperContaier .MuiStepLabel-labelContainer .MuiStepLabel-label {
  margin: 0 !important;
  font-size: 16px !important;
  color: #676c7b !important;
  font-weight: 600 !important;
  font-family: "Poppins", sans-serif;
}

.StapperContaier
  .MuiStepLabel-labelContainer
  .MuiStepLabel-label.Mui-completed {
  margin-top: 0 !important;
  font-size: 16px !important;
  color: #0b132b !important;
  font-weight: 600 !important;
}

.StapperContaier .MuiStepLabel-iconContainer svg {
  color: #a4a7b0;
}

.StapperContaier .MuiStepLabel-iconContainer .Mui-active {
  color: #4c9f41;
  position: relative;
}

.StapperContaier .MuiStepLabel-iconContainer .Mui-active {
  border-radius: 6px !important;
  display: inline-block;
  background: #4c9f41;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.StapperContaier .MuiStepLabel-iconContainer.Mui-completed {
  background: #4c9f41;
  position: relative;
}

.StapperContaier .MuiStepLabel-iconContainer.Mui-completed svg {
  color: #fff;
  display: none;
}

.StapperContaier .MuiStepLabel-iconContainer.Mui-completed::after {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  background: url(../imgs/check_black.svg);
  top: 3px;
  left: 5px;
}

.StapperContaier .MuiStepConnector-horizontal {
  display: none !important;
}

.StapperContaier .MuiStep-root {
  margin-bottom: 38px;
  position: relative;
}

.StapperContaier .MuiStep-root:after {
  content: "";
  border-left: 1px dashed #d7dae3;
  position: absolute;
  height: 55px;
  width: 5px;
  left: 23px;
}

.StapperContaier .MuiStep-root:last-child:after {
  border-left: none;
}

.SpperButtonContainer {
  position: fixed;
  left: 0;
  bottom: 0;
  background: #fff;
  width: 100vw;
  display: flex;
  justify-content: center;
}

.SpperButtonContainer .StepperButton .ButtonContinue {
  background: #4c9f41 !important;
  color: #fff !important;
  box-shadow: none !important;
  height: 48px !important;
  border-radius: 30px !important;
  font-weight: 400;
  text-transform: initial !important;
  font-size: 14px;
  padding: 0 20px;
}

.SpperButtonContainer .StepperButton .ButtonContinue svg {
  font-size: 17px;
  margin-left: 8px;
}

/* ====== Staper Css end ======*/

/* ====== Accordion 3 Step start ======*/

.AccrodionThreeStep .MuiPaper-root {
  background: #fff;
  border: #fff 1px solid;
  border-radius: 12px !important;
  margin-bottom: 8px;
  min-height: 96px;
  box-shadow: 0px 0px 6px #00000017 !important;
}

.AccrodionThreeStep .AccrodionInner .MuiPaper-root {
  background: #f4f7fd;
  box-shadow: none !important;
  border: #e4e8ec 1px solid;
  border-radius: 10px !important;
  margin-bottom: 15px;
}

.AccrodionThreeStep .AccrodionInner .AccThreeStep .MuiPaper-root {
  background: #fff;
  box-shadow: none !important;
  border: #e4e8ec 1px solid;
  border-radius: 10px !important;
  margin-bottom: 15px;
}

.AccrodionThreeStep
  .AccrodionInner
  .AccThreeStep
  .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  transform: rotate(180deg) !important;
  color: #004fe0;
}

.AccrodionThreeStep
  .AccrodionInner
  .AccThreeStep
  .MuiAccordionSummary-expandIconWrapper {
  position: initial;
}

.AccrodionThreeStep .AccrodionInner .AccThreeStep .MuiAccordionSummary-content {
  margin-left: 0px !important;
}

.SessionListing li {
  background: #fff;
  padding: 16px;
  border-radius: 12px;
  border: #e4e8ec 1px solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.SessionListing li .Sessionleftcol {
  display: flex;
  align-items: center;
}

.SessionListing li .Sessionleftcol .SessionrIcon span {
  display: flex;
  width: 24px;
  height: 24px;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  background: #e4e8ec;
  color: #676c7b;
  padding: 4px;
  margin-right: 12px;
}

.SessionListing li .Sessionleftcol .SessionListingDetail h4 {
  margin: 0;
  font-size: 16px;
  color: #0b132b;
  font-weight: 600;
}

.SessionListing li .Sessionleftcol .SessionListingDetail p {
  margin: 0;
  font-size: 14px;
  color: #676c7b;
  font-weight: 400;
}

.SessionListing li .Sessionleftcol .SessionListingDetail a {
  margin: 0;
  font-size: 14px;
  color: #676c7b;
  font-weight: 400;
}

.SessionListing li .Sessionleftcol .SessionListingDetail p svg {
  font-size: 8px;
}

.SessionListing li .SessionListingAction svg {
  color: #676c7b;
}

.SessionListing li .SessionListingAction .lstbtn {
  background: #fff;
  border: #e1e1e7 1px solid;
  border-radius: 10px;
  color: #004fe0;
  font-weight: 600;
  font-size: 14px;
}

.SessionListing li .SessionListingAction .lstbtn svg {
  color: #004fe0;
  font-size: 20px;
  margin-right: 8px;
}

.AccrodionThreeStep .MuiAccordionSummary-expandIconWrapper {
  position: absolute;
  left: 10px;
}

.AccrodionThreeStep .MuiAccordionSummary-content {
  margin-left: 25px !important;
}

.AccrodionThreeStep .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  transform: rotate(90deg) !important;
  color: #004fe0;
}

.accTitleSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.SessionButton {
  background: #004fe0 !important;
  border-radius: 8px !important;
  height: 40px;
  color: #fff !important;
  padding: 0 15px !important;
  text-transform: initial !important;
}

.SessionButton svg {
  color: #fff !important;
  font-size: 20px;
  margin-right: 6px;
}

.SessionButtonEdit {
  color: #004fe0 !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  text-transform: initial !important;
}

.SesstionStatus {
  margin-right: 10px;
}

.SmallDot {
  font-size: 8px !important;
}

.accTitleSectionIcon {
  display: flex;
  align-items: center;
}

.accTitleSectionIcon h4 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.accTitleSectionIcon p {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: #676c7b;
}

.accTitleSectionIcon svg {
  color: #e5e5e5;
  margin-right: 10px;
}

.ScardDetails {
  padding: 0 35px;
}

.ScardDetails p {
  font-size: 14px;
  font-weight: 400;
  color: #676c7b;
}

.Completed {
  color: #0b9444 !important;
}

.MuiPaper-root:before {
  display: none !important;
}

/* ====== Accordion 3 Step End ======*/

.SessionStatus {
  font-size: 14px;
  color: #676c7b;
}

.TextField {
  position: relative;
}

.TextField .MuiOutlinedInput-notchedOutline legend {
  display: none;
}

.TextField .MuiInputAdornment-root {
}

.FieldCount {
  font-size: 12px;
  position: absolute;
  right: 12px;
  bottom: -22px;
}

.MuiFormHelperText-root {
  margin: 4px 0 !important;
}

.priceFiled {
  padding-top: 15px;
  position: relative;
}

.priceFiled .priceICon {
  color: #676c7b;
  font-size: 18px;
  position: absolute;
  bottom: 13px;
  left: 10px;
}

.priceFiled .MuiInputBase-input {
  text-indent: 20px;
}

.ButtonSaveCont {
  position: fixed;
  top: 7px;
  z-index: 999999;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.ButtonSave {
  display: flex;
  width: 74.5%;
  justify-content: end;
}

.ButtonSave .ButtonContainer {
  margin: 0 4px;
}

.ItemCount {
  margin: 0 !important;
  color: #676c7b;
  font-size: 14px;
  font-weight: 400;
}

/* ====== Farhan css ======*/

/* ====== user details css ======*/

.UserStatus {
  display: flex;
  background: #fcfcfc;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-bottom: #e4e8ec 1px solid;
  border-left: #e4e8ec 1px solid;
  border-right: #e4e8ec 1px solid;
}

.UserStatusCol {
  flex: 1;
  border-right: #e4e8ec 1px solid;
  padding: 10px;
  text-align: center;
}

.UserStatusCol:last-child {
  border: none;
}

/* ====== user details css  end======*/

.UserDetails li {
  background: #ffffff;
  padding: 16px;
  border-radius: 12px;
  border: #e4e8ec 1px solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 150px !important; */
  margin-bottom: 0px;
}
.UserDetails li .UserDetailsleftcol {
  display: flex;
  align-items: center;
}

.UserDetailsrIcon {
  margin-right: 24px;
}

.UserDetailsDetail {
}

.UserDetailsDetail h1 {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}

.EditPhoto {
  text-align: center;
  display: flex;
  justify-content: center;
}

.UserDetails li .UserDetailsleftcol .UserDetailsrIcon span {
  display: flex;
  width: 24px;
  height: 24px;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  background: #e4e8ec;
  color: #676c7b;
  padding: 4px;
  margin-right: 12px;
}

.UserDetails li .UserDetailsleftcol .UserDetailsDetail h4 {
  margin-bottom: 2px;
  font-size: 16px;
  color: #0b132b;
  font-weight: 600;
  margin-top: 0;
}

.UserDetails li .UserDetailsleftcol .UserDetailsDetail p {
  margin: 0;
  font-size: 14px;
  color: #676c7b;
  font-weight: 400;
}

.UserDetails li .UserDetailsleftcol .UserDetailsDetail a {
  margin: 0;
  font-size: 14px;
  color: #676c7b;
  font-weight: 400;
}

.UserDetails li .UserDetailsleftcol .UserDetailsDetail p svg {
  font-size: 8px;
}

.UserDetails li .UserDetailsAction svg {
  color: #676c7b;
}

.UserDetails li .UserDetailsAction .lstbtn {
  background: #fff;
  border: #e1e1e7 1px solid;
  border-radius: 10px;
  color: #004fe0;
  font-weight: 600;
  font-size: 14px;
}

.UserDetails li .UserDetailsAction .lstbtn svg {
  color: #004fe0;
  font-size: 20px;
  margin-right: 8px;
}

.PaymentDetails {
  background-color: #2313b70f;
  padding: 16px;
  border-radius: 12px;
  border: #084f820f 1px solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.OwnerPortalPaymentDetails {
  background-color: #f2f7f30f;
  padding: 16px;
  border-radius: 12px;
  border: #084f820f 1px solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.BigIcon {
  font-size: 12px;
}

/* ====== Landing Page======*/
.Landing {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
}

.Landing a {
  border: #e9e9e9 1px solid;
  padding: 60px;
  border-radius: 28px;
  margin: 15px;
  display: inline-block;
  background: #fff;
  text-decoration: none;
  font-size: 20px;
  text-transform: initial;
}

.AccrodionThreeStep {
  margin-bottom: 20px;
}

.AccrodionThreeStep:last-child {
  margin-bottom: 0px;
}

.HelperText {
  font-size: 14px;
  color: #676c7b;
  margin-top: 8px;
}

.UplaodPhotoModal .HelperText {
  text-align: center;
  max-width: 300px;
  margin: 0 auto;
  padding-top: 20px;
}

.divider {
  width: 100%;
  height: 1px;
  display: inline-block;
  border-bottom: 1px solid rgb(238, 237, 237);
}

.UpdateUseravatar {
  display: flex;
  align-items: center;
}

.UplaodPhotoModal {
  flex: 1;
}

.RedioButton .MuiFormControlLabel-root {
}

.RedioButton .MuiFormGroup-root {
  display: flex;
  flex-wrap: nowrap !important;
  flex-direction: row !important;
}

.addText {
  font-size: 14px;
  color: #004fe0;
  cursor: pointer;
}

.emptySettionMessage {
  color: #a4a7b0;
  font-size: 14px;
  text-align: center;
}
.checkBox {
  margin-top: 10px;
}
.checkBox .MuiTypography-root {
  font-size: 14px;
  color: #0b132b;
  font-family: "Poppins", sans-serif !important;
}

.StapperBody .MuiTooltip-tooltip {
  left: 0 !important;
  top: 0 !important;
  position: fixed !important;
}

.VerifyChip {
  background: #e1f2e8;
  color: #0b9444;
  font-size: 14px;
  padding: 3px 5px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  margin-left: 10px;
}

.VerifyChip svg {
  color: #0b9444;
  font-size: 18px;
  margin-right: 4px;
}

.cellwithchip {
  display: flex;
  align-items: center;
  font-weight: 500;
}

.actionDoropdown .MuiButtonBase-root {
  border: #e4e8ec 1px solid;
  height: 48px;
  padding: 0 15px;
  text-transform: initial !important;
  font-weight: 600;
  border-radius: 1000px !important;
}

.MuiOutlinedInput-notchedOutline {
  border: #e4e8ec 1px solid !important;
}

.MarkCompleted {
  display: flex;
  align-items: center;
  margin-top: 15px;
  border-top: #e9e9e9 1px solid;
  padding-top: 10px;
  padding-bottom: 5px;
  cursor: pointer;
}

.MarkCompleted span {
  color: #004fe0;
  font-size: 14px;
  font-weight: 600;
}

.MarkCompleted svg {
  display: flex;
  color: #e4e8ec;
  margin-right: 8px;
  font-size: 30px;
}

.MuiIconButton-edgeEnd {
  margin-right: -12px;
  position: relative;
  top: -2px;
}

.LargeText .TextField .MuiInputBase-root {
  width: 100%;
  height: 70px;
  font-size: 34px;
  font-weight: 700;
}

.LargeText .FieldLabel {
  font-size: 20px;
  color: #0b132b;
  font-weight: 500;
  margin-bottom: 10px;
}

.AvailaleContainer {
  position: relative;
}

.AvailableButton {
  color: #004fe0;
  font-size: 14px;
  position: absolute;
  right: 20px;
  top: 58px;
  cursor: pointer;
  font-weight: 600;
}

.AvailVerify {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  padding-top: 5px;
}

.AvailVerify svg {
  font-size: 19px;
  margin-right: 4px;
}

.AvailVerify.Yes {
  color: #0b9444;
}

.AvailVerify.No {
  color: #ba1a1a;
}

.LoadingBar {
  position: absolute;
  right: 20px;
  top: -10px;
}

.AvailableButton.IfLoading {
  color: #676c7b;
}

.ActionButton .MuiButtonBase-root {
  border: #e4e8ec 1px solid;
  border-radius: 1000px;
  height: 48px;
  border-radius: 8px;
  padding: 0 20px;
  text-transform: initial !important;
  color: #4c9f41;
  font-weight: 600;
}

.BalanceCard .BalanceCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.BalanceCardHeader h1 {
  font-size: 20px;
  color: #0b132b;
  margin: 0;
}

.BalanceCard .BalanceCardBody h2 {
  font-size: 48px;
  color: #0b132b;
  margin: 0;
  font-weight: 600;
}

.BalanceCard .BalanceCardBody p {
  font-size: 16px;
  color: #676c7b;
  margin: 0;
}

.dropdownSpan {
  margin: 0 0 0 10px;
  font-size: 14px;
  color: #77767a;
  display: inline-block;
}

/* ------------------ addPhone Modal -------------------- */
.addPhoneModalContainer p {
  margin: 0;
  color: #0b132b;
  text-align: center;
  font-size: 14px;
}
/* ------------------ addPhone Modal -------------------- */
.newAlretNotification {
  height: 452px;
  width: 452px;
  border-radius: 50%;
  background-color: #4c9f41;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: heartbeat 1.5s infinite;
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.newAlretNotification h1 {
  margin: 0;
  font-size: 15rem;
  font-weight: 400;
}

.newAlretNotificationButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.newAlretNotificationButton h2 {
  margin: 0;
  font-size: 76px;
  font-weight: 600;
}

/* ------------------ new order alert -------------------- */

/* ------------------ menu bar start --------------------- */
.ResponsiveMenu {
  display: none;
}

.Branding {
  display: none;
}

@media (max-width: 1300px) {
  .DesktopView {
    display: none;
  }

  .Branding {
    display: block;
  }

  .ResponsiveMenu {
    display: block;
  }

  .SideBarDrawer .MuiDrawer-paperAnchorLeft {
    width: 250px;
  }

  .SidebarMenu {
    width: 250px;
    max-width: initial;
  }

  .SideBarDrawer .MuiDrawer-paper {
    overflow-y: initial;
  }
}

/* ==================================== RESPONSIVE =============================================== */

@media (max-width: 1500px) {
  .app-body {
    width: 79%;
    height: 400px;
    margin-left: 11%;
    padding: 20px;
  }
}

@media (max-width: 1300px) {
  .app-body {
    width: auto;
    height: 400px;
    margin-left: 0;
    padding: 20px;
  }
}

/* ==================================== RESPONSIVE =============================================== */

.IconCell {
  display: flex;
}

.IconCell img {
  margin-left: 4px;
  width: 18px;
}
