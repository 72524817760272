.MuiTooltip-tooltip{
    background: #0B132B !important;
    font-size: 12px !important;
    line-height: 20px !important;
    padding: 10px !important;
}

.MuiTooltip-arrow{
    color: #000 !important;
}


