.itemDetailsGrandContainer {
  padding: 56px;
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
}

.itemDetailsGrandContainer h3 {
  margin: 0;
  font-size: 22px;
  font-weight: 400;
}

.MenuItemBtnContainer .MuiButton-root {
  width: 100% !important;
  padding: 8px 0;
  color: #4c9f41;
  text-transform: capitalize;
}

.MediaLibWarning {
  color: #767680;
  font-size: 12px;
}
