.boxShadow {
  box-shadow: 0 0 3px 3px #f3f2f5;
}

.flex {
  display: flex;
}
.alignItem {
  align-items: center;
}
.justifyContent {
  justify-content: center;
}
.justifyBetween {
  justify-content: space-between;
}

/* -------------------- orderNumberContainer start -------------------- */
.orderNumberContainer {
  padding: 1.5rem;
  border-radius: 12px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.orderNumberContainer h4 {
  margin: 0;
  font-size: 24px;
  font-weight: 500;
}

.orderNumberContainer p {
  margin: 0;
  color: #77767a;
}

/* -------------------- orderNumberContainer end ------------------------- */

/* -------------------- sectionUserInfoContainer start --------------------- */
.sectionUserInfoContainer {
  padding: 12px;
  border-radius: 12px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  gap: 1rem;
  /* justify-content: space-between; */
}

.sectionAvater {
  height: 40px;
  width: 40px;
  background-color: #dd2d37;
  border-radius: 50%;
  overflow: hidden;
}

.sectionAvater img {
  height: 40px;
  width: 40px;
  object-fit: contain;
}

.sectionUserInfo h4 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.sectionUserInfo p {
  margin: 0;
  font-size: 14px;
  color: #46464a;
}
/* -------------------- sectionUserInfoContainer end ----------------------- */

/* -------------------- orderDeliveryContainer start ----------------------- */
.orderDeliveryContainer {
  padding: 12px;
  height: 100%;
  background-color: #ffffff;
  border-radius: 12px;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.deliveryAvatar {
  padding: 10px 10px;
  border-radius: 50%;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.deliveryInfo h4 {
  margin: 0;
  font-weight: 500;
}

.deliveryInfo p {
  margin: 0;
  color: #46464a;
}

.DeliveryDetailsContainer {
  padding: 16px;
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  gap: 8px;
  align-items: flex-start;
}
.DeliveryDetailsContainer p {
  margin: 0;
  color: #0450e1;
}
/* -------------------- orderDeliveryContainer end ------------------------- */
