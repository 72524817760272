.MuiDialog-paper {
  border-radius: 28px !important;
  padding: 24px;
}

.DialogTitle {
  color: #0b132b;
  font-size: 24px;
  font-family: "Poppins", sans-serif !important;
}

.DialogContentText {
  color: #0b132b;
  font-size: 14px;
  font-family: "Poppins", sans-serif !important;
}

.DialogActions .MuiButtonBase-root {
  text-transform: initial !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
}

/* .DialogActionsBlack{
    color: #0B132B !important; 
} */

.DialogActionsgreen {
  color: #4c9f41 !important;
}

.changeStatusDialog .MuiDialog-paper .MuiPaper-root {
  min-width: 1200px;
  padding: 12px;
}
