.TextField{
    display: flex;
    flex-direction: column;
    position: relative;
}
.inputField {
    width: 100%;
}
.FieldLabel{
    color : #676C7B;
    display: flex;
    align-items: flex-start; 
}

.FieldCount{
    position: absolute;
    top: 43px;
    right: 12px;
    font-size: 14px;
    align-self: flex-end;
}