.ordersTablemptyCardContainer{
    padding: 40px 0;
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ordersTablemptyCardContainer h2{
    margin: 32px 0 0 0;
    font-size: 24px;
    font-weight: 600;
}

.ordersTablemptyCardContainer p{
    margin: 0;
    color: #5E5E62;
}