.orderDetailsGradParent{
    padding: 24px;
    background-color: #fff;
    border-radius: 8px;
}

.orderlistParentContainer{
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom:1px solid #EAE9EC;
    display: flex;
    gap: 1rem;
    align-items: flex-start;
    justify-content: space-between;
  }
  
  .orderlistParentContainer P {
    margin: 2px 0 0 0;
    font-size: 22px;
  }

  .orderlistItemDetailsContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .orderlistItemDetailsContainer h2{
    margin: 0;
    font-weight: 600;
  }

  .orderlistItemInfo {
  margin: 10px 0 0 15px;
  padding-left: 1rem;
  border-left: 2px solid #ADADAF;
}

.orderlistItemInfo span{
  margin: 0;
  color: #77767A;
  font-size: 14px;
}

.orderlistItemInfo p{
  margin: 0;
  /* color: #77767A; */
  font-size: 16px;
  font-weight: 600;
}

.listFooterParentContainer {
  margin: 10px 0;
  padding: 10px 0 0 0;
  /* border-top: 1px solid #ECEBEE; */
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.listFooterContainer {
  width: 50%;
}

.listFooterItem {
  margin: 8px 0;
  display: flex;
  justify-content: space-between;
}

.listFooterContainer P {
  margin: 0;
  color: #1B1B1F;
}