.defaultLocationContainer {
  padding: 10px 0;
  margin-bottom: 10px;
  border-top: 1px solid #e9ecef;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.defaultLocationContainer h3 {
  margin: 0;
  color: #1b1b1f;
  font-size: 22px;
}

.defaultLocationContainer p {
  width: 40%;
  color: #767680;
}
