@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.SidebarMenu {
  width: 100%;
  max-width: 128px;
  position: fixed;
  z-index: 9999;
  top: 0;
  bottom: 0;
  left: 0;
  background: #1b1b1f !important;
  box-shadow: 0px 1px 3px #0000001a;
}
.SidebarMenu .MuiList-root .MuiButtonBase-root .MuiSvgIcon-root {
  color: #ffff !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.SidebarMenu .MuiList-root .MuiButtonBase-root {
  padding: 0 !important;
}

.active-route svg {
  background-color: #dd2d37;
}

.active-route .MuiListItemIcon-root {
  background-color: #dd2d37;
}

.SidebarMenu .MuiListItemIcon-root {
  height: 40px;
  width: 72px;
  border-radius: 100px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.SidebarMenu .MuiList-root .MuiButtonBase-root a {
  width: 100%;
  padding: 14px 18px;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
}

.SidebarMenu .MuiList-root .MuiButtonBase-root a {
  display: inline-block;
  text-decoration: none;
  color: #ffffff !important;
  font-size: 17px;
  font-weight: 400 !important;
  font-family: "Poppins", sans-serif;
}

.SidebarMenu .MuiList-root .MuiButtonBase-root .MuiSvgIcon-root {
  color: #ffffff;
}

.SidebarMenu .divider {
  width: 278px;
  height: 1px;
  display: inline-block;
  border-bottom: 1px solid rgb(238, 237, 237);
}

@media (max-width: 1300px) {
  .SidebarMenu {
    width: 250px;
    max-width: initial;
  }
}

@media (max-width: 768px) {
  .DrawerBody {
    padding: 20px;
    width: 100%;
  }

  .DrawerFooterInner {
    display: block;
    text-align: left;
  }

  .DrawerCloseButton {
    display: none !important;
  }

  .DrawerCloseButtonMobile {
    display: block !important;
    margin: 0 auto !important;
  }

  .PeronalInfocard .PeronalInfocardDetail .MuiChip-root {
    margin-top: 8px;
  }

  .TabFooter {
    margin-left: -20px;
  }
}

@media (max-width: 468px) {
  .DrawerFooter .DrawerFooterInner .DrawerActionButton {
    width: 100%;
    margin-bottom: 5px;
  }

  .DrawerFooterButton {
    display: block;
    align-items: center;
  }

  .DrawerBody {
    padding: 20px;
    width: 320px;
  }
}
