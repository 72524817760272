
.MuiFormControl-root{
    width: 100%;
}

.Datefield .MuiInputBase-input{
    height: 48px !important;
    padding: 0 15px !important;

}

.Datefield .MuiInputLabel-outlined{
    top: -3px;
}