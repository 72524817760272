.AddField {
  background: #f1f7f0;
  border-radius: 12px;
  position: relative;
  padding: 15px 25px 15px 15px;
  margin-bottom: 8px;
}

.AddField .MuiFormControl-root {
  width: 96%;
  background: #fff;
}

.AddField .MuiFormControl-root.MuiTextField-root {
  padding: 5px 0 0 0;
  border-radius: 12px;
}

.AddField .RemoveField {
  min-width: 16px;
  position: absolute !important;
  right: 12px;
  margin-top: -135px;
  color: #676c7b;
}

.AddField .RemoveField svg {
  font-size: 22px;
}

.AddField .MuiInputBase-root {
  width: 100%;
  height: 46px;
  border-radius: 0 !important;
}

.AddField
  .MuiFormControl-root
  .MuiInputBase-root
  .MuiOutlinedInput-notchedOutline {
  border-color: #e4e8ec;
  border-radius: 0 !important;
}

.AddField .MuiFormControl-root .MuiFormHelperText-root {
  background-color: #f1f7f0;
  margin: 0 !important;
}

.AddField .FieldCount {
  position: absolute;
  right: 62px;
  top: 30px;
  bottom: 129px;
}

.AddField .FieldCount span {
  font-size: 16px;
}

.AddField .MuiInputBase-input {
  padding-right: 72px;
}

.AddMoreButton {
  font-weight: 600 !important;
  font-size: 14px !important;
  color: #4c9f41 !important;
  text-transform: initial !important;
}

.InnerFieldCountp {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-48%);
  color: #676c7b;
  font-size: 14px;
}
