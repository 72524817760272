.orderStatusContainer {
  border-radius: 12px;
  overflow: hidden;
  height: 100%;
}

.orderStatusContainer .MuiList-root {
  height: 100%;
  display: flex;
}
