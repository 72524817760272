.profilePictureContainer {
    height: 104px;
    width: 104px;
    border-radius: 50%;
    font-size: 40px;
    color: #ffffff;
    background-color: #DD2D37;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profilePictureContainer img {
    height: 104px;
    width: 104px;
}