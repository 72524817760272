.HeaderTwo {
    display: flex;
    justify-content: space-between;
    padding: 25px;
}

.HeaderTwo a img {
    width: 100px;
}

.HeaderTwo .HeaderTwoR {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
}

.HeaderTwo .HeaderTwoR span {
    margin: 0;
    color: #767680;
}


.HeaderTwo .HeaderTwoR a {
    color: #0B132B;
    font-size: 14px;
}


.FormHeaHeaderTwoder .HeaderTwoR span {
    color: #676C7B;
    font-size: 14px;
    margin-right: 10px;
}


