.HeaderContainer {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  left: 0;
}

.HdrLeftCol {
  margin-left: 150px;
  /* width: 360px; */
  justify-self: flex-start;
  /* display: flex;
  align-items: center;
  justify-content: space-between; */
  flex: 1;
}

.LocationMenu {
  /* border-top: 1px solid rgb(238, 237, 237); */
  /* border-bottom: 1px solid rgb(238, 237, 237); */
  /* width: 199px; */
  height: 36px;
  /* margin-left: 10rem; */
  padding-top: -18px;
  padding-bottom: 4px;
}

.LocationMenu a {
  display: flex;
  text-decoration: none;
  font: 14px Poppins;
  letter-spacing: 0px;
  color: #1b1b1f;
  opacity: 1;
  font-weight: 500;
}

.LocationMenu .MuiSvgIcon-root {
  fill: #4c9f41 !important;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.2rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
  padding-top: 12px;
}

.Header {
  background: #fff;
  height: 68px;
  width: 100vw;
  padding: 10px 25px;
  box-shadow: 0px 1px 3px #0000001a;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Header .Branding img {
  height: 44px;
}

.HdrRightCol {
  display: flex;
}

.Header .HdrRightCol .MuiAvatar-root {
  margin-left: 16px !important;
  border-radius: 10px;
  cursor: pointer;
}

.NotificationButton {
  border: #d2d3d8 1px solid !important;
  border-radius: 10px !important;
  width: 40px !important;
  height: 40px !important;
  min-width: initial !important;
  min-height: initial !important;
  position: relative;
}

.NotificationButton svg {
  color: #004fe0;
}

.NotificationButton .NotificationCount {
  background: #e63946;
  text-align: center;
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 100px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  right: -10px;
  top: -7px;
}

/* === Dropwdown menue == */

.DropdownMenu .MuiPopover-paper {
  top: 61px !important;
  width: 352px;
  margin-left: -10px;
  box-shadow: 0px 2px 17px #00000017 !important;
  border-radius: 0 0 10px 10px !important;
}

.ProfileSection {
  display: flex;
  align-items: center;
}

.ProfileSection h1 {
  font-size: 16px;
  color: #0b132b;
  margin: 0;
  line-height: 20px;
}

.ProfileSection p {
  margin: 0;
}

.ProfileSection p a {
  font-size: 12px;
  color: #676c7b;
  text-decoration: none;
}

.ProfileSection .MuiAvatar-root {
  margin-right: 16px;
  width: 60px;
  height: 60px;
}

.DropdownMenuList {
}

.DropdownMenu .DropdownMenuList .MuiListItem-root {
  padding: 15px 25px !important;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px !important;
  font-family: "Poppins", sans-serif;
}

.DropdownMenu .DropdownMenuList .MuiListItem-root .MuiSvgIcon-root {
  margin-right: 12px;
}

@media only screen and (max-width: 1310px) and (min-width: 768px) {
  .HdrLeftCol {
    margin-left: 0px !important;
    /* background-color: red; */
    justify-self: flex-start;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .Branding {
    flex: 1;
    margin-left: 1rem;
  }
}

@media (max-width: 768px) {
  .HdrLeftCol {
    margin-left: 0px !important;
    display: flex;
    align-items: center;
    gap: 12px;
  }
}
