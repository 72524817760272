.ButtonContainer :global .MuiButtonBase-root {
    border-radius: 12px !important;
    padding: 0 20px;
    text-transform: initial !important;
    font-weight: 500 !important;
    /* margin-left: 10px !important; */
}

.ButtonContainer :global .Mui-disabled {
    background: #E1E2E5 !important;
    color: #A7AAB3 !important;
}

.ButtonContainer :global .Mui-disabled {
    background: #E1E2E5 !important;
    color: #A7AAB3 !important;
}