.styledBox {
  padding: 1rem;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.activeBox {
  border: 2px solid #7ab840;
  color: #7ab840;
}

.activeBox h2 {
  font-weight: 400;
  font-size: 20px;
}

.activeBox span {
  font-weight: 400;
  font-size: 14px;
}

.deActiveBox {
  border: 2px solid #e4e8ec;
}

.deActiveBox .addInfo h2 {
  color: #1b1b1f !important;
  font-weight: 400;
  font-size: 20px;
}

.deActiveBox .addInfo span {
  color: #a4a7b0 !important;
  font-weight: 400;
  font-size: 14px;
}

.addInfo {
  width: 90%;
}

.addInfo h2 {
  margin: 0 !important;
}
.addInfo h4 {
  margin: 9px 0 !important;
  color: #676c7b !important;
}

.addInfo a {
  font-size: 14px !important;
  color: #7ab840 !important;
  text-decoration: underline !important;
}
