.Timeline{
    padding: 24px;
    /* margin-top: 50px; */
    border-radius: 8px;
    background-color: #fff;
}

.Timeline h2{
    margin: 0;
    font-weight: 500;
}

/* .Timeline .MuiTimeline-root:first-child .MuiTimelineItem-root .MuiTimelineSeparator-root .MuiTimelineDot-root{
    margin-top: 13px !important;
} */

.Timeline ul li .MuiTimelineContent-root {
    margin-top: -13px !important;
} 

.Timeline .MuiTimeline-root .MuiTimelineItem-root .MuiTimelineSeparator-root .MuiTimelineDot-root {
    margin: 0 ;
    color: #000000;
    border-color: transparent;
    background-color: #000000;
}
.Timeline .MuiTimeline-root .MuiTimelineItem-root .MuiTimelineSeparator-root .MuiTimelineConnector-root {
    width: 1px;
    background-color: #000000 !important
}