@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.FeatureCardHeader {
    display: flex;
}

.Orders h1 {
    margin-top: 10px;
    text-align: left;
    font: normal normal normal 32px/40px Poppins;
    font-weight: 600;
    letter-spacing: 0px;
    color: #1B1B1F;
    opacity: 1;
}

.NewOrders {
    padding: 1rem;
    height: 125px;
    background-color: #4C9F41;
    border-radius: 13px;
    display: flex;
    gap: 15px;
    align-items: center;
}

.NewOrdersButton{
    width: 552px;
    height: 125px;
    background-color: #4C9F41;
    border-radius: 13px;
    color: #1B1B1F;
    display: flex;
}

.Avatar {
    width: 90px;
    height: 90px;
    background-color: #8eb788;
    border-radius: 80px;
}

.Ordercontent {
    height: 90%;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.Ordercontent h2 {
   margin: 0;
   font-size: 22px;
   font-weight: 600;
}

.Ordercontent h4 {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
}

.ordercountlabel {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.ordercountlabel h3 {
    margin: 0;
}

.Ordercontent h2 {
    margin: 0;
}

span {
    display: flex;
    justify-content: space-between;

}


.AvtrIcon {
    color: #FFFFFF;
}

/* -------------------Model details------------------------------ */
.ModelDetails {
    margin: 0 auto;
    width: 730px;
    padding: 30px;
    border-radius: 32px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}

.ModelHeader {
    background-color: #DD2D37;
    height: 106px;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;

}


.ModelHeader h1 {
    color: var(--onprimary-ffffff);
    text-align: left;
    font: normal normal 600 24px/32px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin-top: 20px;
    margin-left: 30px;
}

.ModelHeader p {
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--onprimary-ffffff);
    text-align: left;
    font: normal normal normal 16px/24px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 0.8;
    margin-left: 30px;

}


.NameContent {
    display: flex;
    justify-content: space-around;

}

.NameContent p {
    line-height: 3px;
    text-align: left;
    font: normal normal normal 16px/24px Poppins;
    letter-spacing: 0px;
    color: #767680;
    opacity: 1;
}

.Namedetails {
    background-color: #DD2D37;
    width: 70px;
    height: 70px;
    border-radius: 70px;
    display: flex;
    justify-content: center;
    margin-top: 26px;
    margin-left: 40px;
}

.Namedetails p {
    margin-top: 25px;
    font: normal normal medium 16px/24px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.Name {
    margin-top: 24px;
    margin-left: 15px;
}

.Name h1 {
    text-align: left;
    font: normal normal 600 22px/28px Poppins;
    letter-spacing: 0px;
    color: #1B1B1F;
    opacity: 1;
    line-height: 10px;
    margin-left: 4px;
}

.NameContent .css-i4bv87-MuiSvgIcon-root {
    width: 1em;
    height: 1em;
    display: inline-block;
    fill: #767680 !important;
    font-size: 9px !important;

}

.Dropdown .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    font-size: 1rem;
    background-color: #4C9F41;
    box-sizing: border-box;
    border-radius: 10px;
}

.Dropdown {
    height: 64px;
    width: 660px;
    margin-left: 30px;
    border: none;

}

.Dropdown .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
    border: none;
    display: inline-block;
    fill: #fff !important;
    right: 7px;

}

.ButtonGroup {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.ButtoonOne {
    margin-left: 5px;
}

.Buttontwo {
    /* margin-left: 5px; */
    color: #FFFFFF !important;
}




/* -----------------Orders details Header-------------------------------- */
.OrderdeatilsHeader {
    display: flex;
    justify-content: space-between;
}

.orderDetails {
    margin-top: 7rem;
}

.Namedetail {
    background-color: #DD2D37;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    margin-top: -5px;
    margin-left: 15px;
}

.Namedetail p {
    margin-top: 16px;
    font: normal normal medium 16px/24px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}
