.TextArea{
    position: relative;
}

.TextArea .CharCount{
    color: rgb(182, 185, 185);
    font-size: 12px;
}


.TextArea textarea{
border: #E4E8EC 1px solid;
border-radius: 4px;
min-height: 40px;
width: 96%;
padding: 2%;
font-size: 17px;
}

.HelperTextCounter{
    display: flex;
    justify-content: space-between;
}

