.react-tel-input .country-list .country {
    padding: 6px 9px 13px 46px !important;
    position: relative;
}

/* .react-tel-input .country-list .flag {
    margin-right: 7px;
    margin-top: -4px;
} */

.PhoneFieldManContainer .react-tel-input .form-control {
    height: 48px !important;
    border-radius: 8px !important;
}

.PhoneFieldManContainer .react-tel-input .selected-flag {
    border-radius: 8px 0 0 8px !important;
    width: 42px !important;
}

.PhoneFieldManContainer .react-tel-input .special-label {
    display: none !important;
}

.PhoneFieldManContainer .react-tel-input .flag-dropdown {
    border-radius: 8px 0 0 8px !important;

}

.error .PhoneFieldManContainer .react-tel-input .form-control {
    border-color: #f44336 !important;
}

.error .PhoneFieldManContainer .react-tel-input .selected-flag {
    border-color: #f44336 !important;
}